import { axiosInstance } from "./axiosSetup";
import { handleErrors } from "pubtrack-frontend-library";
import { institutionsPercentage } from "../mocks/institutionsPercentage";
import { enrichmentTypesPercentage } from "../mocks/enrichmentTypesPercentage";
import { noPidInstitutions } from "../mocks/noPidInstitutions";
import { unconfirmedInstitutions } from "../mocks/unconfirmedInstitutions";
import { noRinggoldInstitutions } from "../mocks/noRinggoldInstitutions";


export async function getInstitutionDetails(id) {
    return await axiosInstance
        .get(`pubtrack/tracker/reports/institution/articles?query=${id}`)
        .catch(handleErrors)
};

export async function getInstitutionArticlesPerMonth(id) {
    return await axiosInstance
        .get(`pubtrack/tracker/reports/lastyear/articles?ror=${id}`)
        .catch(handleErrors)
};

export async function getInstitutionJournals(id) {
    return await axiosInstance
        .get(`pubtrack/tracker/reports/articles/journals?ror=${id}`)
        .catch(handleErrors)
};

export async function getInstitutionsPercentage() {
    // return await axiosInstance
    // .get(`hindawi/tracker/reports/articles/identifiers`)
    // .catch(handleErrors)
    return Promise.resolve({ data: institutionsPercentage });
};

export async function getEnrichmentTypesPercentage() {
    // return await axiosInstance
    // .get(`hindawi/tracker/reports/articles/identifiers`)
    // .catch(handleErrors)
    return Promise.resolve({ data: enrichmentTypesPercentage });
};

export async function getNoPidInstitutions() {
    return await axiosInstance
        .get(`pubtrack/tracker/reports/institutions/withoutidentifiers`)
        .catch(handleErrors)
    // return Promise.resolve({ data: noPidInstitutions });
};

export async function saveInstitutionRor(id, data) {
    return await axiosInstance
        .put(`pubtrack/tracker/article/institutions/${id}`, data)
        .catch(handleErrors)
    // return Promise.resolve();
};

export async function getUnconfirmedInstitutions() {
    return await axiosInstance
        .get(`pubtrack/tracker/institutions`)
        .catch(handleErrors)
    //  return Promise.resolve({ data: unconfirmedInstitutions });
};

export async function confirmInstitution(id) {
    return await axiosInstance
        .put(`pubtrack/tracker/institution/${id}`)
        .catch(handleErrors)
    //  return Promise.resolve({});
};

export async function getNoRinggoldInstitutions() {
    return await axiosInstance
        .get(`pubtrack/tracker/institutions/ringgold`)
        .catch(handleErrors)
    //  return Promise.resolve({ data: noRinggoldInstitutions });
};

export async function saveRinggoldInstitution(id, data) {
    return await axiosInstance
        .put(`pubtrack/tracker/institution/ringgold/${id}`, data)
        .catch(handleErrors)
};
