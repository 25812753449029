import React, { useEffect, useState } from "react";
import { MDBCol, MDBRow, MDBBtn, MDBTabPane } from 'mdbreact';
import { getArticleDetails, getNoPidArticles, updateArticleDetails } from "../../helpers/articles";
import { getInstitutionsPercentage, getEnrichmentTypesPercentage, getUnconfirmedInstitutions, confirmInstitution, getNoPidInstitutions, saveInstitutionRor, getNoRinggoldInstitutions, saveRinggoldInstitution } from "../../helpers/institutions";
import {
    PieChart,
    Card,
    ArticleDetailsModal,
    Table,
    Tabs,
    showSuccessToast,
    showErrorToast,
    InstitutionTableCell,
    RinggoldInstitutionTableCell
} from "pubtrack-frontend-library";
import { userSettings } from "../../user-settings";


const Enrichment = () => {
    const [institutionsPercentage, setInstitutionsPercentage] = useState([]);
    const [typesPercentage, setTypesPercentage] = useState([]);
    const [selectedMessage, setSelectedMessage] = useState(null);
    const [articleDetails, setArticleDetails] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [incompleteArticles, setIncompleteArticles] = useState([]);
    const [noPidInstitutions, setNoPidInstitutions] = useState([]);
    const [unconfirmedInstitutions, setUnconfirmedInstitutions] = useState([]);
    const [noRinggoldInstitutions, setNoRinggoldInstitutions] = useState([]);

    const tabs = ["Incomplete articles", "No PID", "Confirmation", "Ringgold mapping"];

    useEffect(() => {
        getInstitutionsPercentage()
            .then(res => setInstitutionsPercentage(res.data));

        getEnrichmentTypesPercentage()
            .then(res => setTypesPercentage(res.data));

        getNoPidArticles()
            .then(res => setIncompleteArticles(res.data));

        getNoPidInstitutions()
            .then(res => setNoPidInstitutions(res.data));

        getUnconfirmedInstitutions()
            .then(res => setUnconfirmedInstitutions(res.data));

        getNoRinggoldInstitutions()
            .then(res => setNoRinggoldInstitutions(res.data));
    }, []);

    const renderInstitutions = item => {
        return item.institutions.length ?
            item.institutions.map(item => item.name ? item.name : null).join(', ')
            :
            null;
    };

    const renderAuthors = authors => {
        return authors.length ?
            authors.map(item => item.institutions ? renderInstitutions(item) : null)
            :
            "-";
    };

    const incompleteArticlesColumns = React.useMemo(
        () => [
            {
                Header: 'title',
                accessor: 'title',
            },
            {
                Header: 'No PID',
                id: 'no_pid',
                Cell: ({ row }) =>
                    <span>{renderAuthors(row.original.authors) || "-"}</span>
            },
        ], []
    );

    const noPidInstitutionsColumns = [
        {
            Header: 'institution',
            accessor: 'institution',
        },
        {
            Header: '',
            id: 'confirm',
            Cell: ({ row }) =>
                <InstitutionTableCell
                    row={row.original}
                    saveRor={saveRor}
                />
        }
    ];

    const unconfirmedInstitutionsColumns = [
        {
            Header: 'institution',
            accessor: 'institution',
        },
        {
            Header: 'RORID',
            accessor: 'rorid',
            Cell: ({ row }) =>
                <a href={row.original.rorid || row.original.ror} target="_blank" className="link">{row.original.rorid || row.original.ror}</a>
        },
        {
            Header: 'ROR name',
            accessor: 'rorname',
        },
        {
            Header: '',
            id: 'confirm',
            Cell: ({ row }) =>
                <MDBBtn color="primary" size='sm' onClick={(e) => onConfirmInstitution(e, row)}>confirm</MDBBtn>
        },
    ];

    const noRinggoldInstitutionsColumns = [
        {
            Header: 'institution',
            accessor: 'institution',
        },
        {
            Header: 'RORID',
            accessor: 'rorid',
            Cell: ({ row }) =>
                <a href={row.original.rorid || row.original.ror} target="_blank" className="link">{row.original.rorid || row.original.ror}</a>
        },
        {
            Header: 'ringgold',
            id: 'ringgold',
            Cell: ({ row }) =>
                <RinggoldInstitutionTableCell
                    row={row.original}
                    saveRinggold={saveRinggold}
                />
        }
    ];

    const onSelectRow = row => {
        const messageId = row && row.original ? row.original["id"] : null;
        setSelectedMessage(messageId);
    };

    useEffect(() => {
        if (selectedMessage) {
            getArticleDetails(selectedMessage)
                .then(res => setArticleDetails(res.data))
                .then(setIsOpen(true))
        }
    }, [selectedMessage]);

    const onConfirmInstitution = (e, row) => {
        e.stopPropagation();
        const institutionId = row && row.original ? row.original["id"] : null;

        if (institutionId) {
            confirmInstitution(institutionId)
                .then(res => {
                    showSuccessToast("Confirmed");
                    getUnconfirmedInstitutions()
                        .then(res => setUnconfirmedInstitutions(res.data));
                })
                .catch(error => {
                    showErrorToast("Error");
                });
        };
    };

    const saveRor = async (id, data) => {
        saveInstitutionRor(id, data)
            .then(res => {
                getNoPidInstitutions()
                    .then(res => setNoPidInstitutions(res.data));
            });
    };

    const saveRinggold = async (id, data) => {
        saveRinggoldInstitution(id, data)
            .then(res => {
                getNoRinggoldInstitutions()
                    .then(res => setNoRinggoldInstitutions(res.data));
            });
    };

    const updateArticle = data => {
        return saveInstitutionRor(selectedMessage, data)
    };


    return (
        <div className="container">
            <MDBRow className="mt-5 d-flex justify-content-center">
                <MDBCol md='3' className="my-4">
                    <Card
                        title="Enriched/not-enriched with RORID:"
                        content={<PieChart data={institutionsPercentage} labelKey="label" valueKey="count" />}
                    />
                </MDBCol>
                <MDBCol md='3' className="my-4">
                    <Card
                        title="Enrichment type:"
                        content={<PieChart data={typesPercentage} labelKey="label" valueKey="count" showInPercentage />}
                    />
                </MDBCol>
            </MDBRow>

            <div className="my-5">
                <Tabs
                    tabs={tabs}
                    content={
                        <>
                            <MDBTabPane tabId="Incomplete articles" role="tabpanel">
                                <Table
                                    data={incompleteArticles}
                                    columns={incompleteArticlesColumns}
                                    onSelectRow={onSelectRow}
                                    showCount={true}
                                />
                            </MDBTabPane>

                            <MDBTabPane tabId="No PID" role="tabpanel">
                                <Table
                                    data={noPidInstitutions}
                                    columns={noPidInstitutionsColumns}
                                    onSelectRow={onSelectRow}
                                    showCount={true}
                                />
                            </MDBTabPane>

                            <MDBTabPane tabId="Confirmation" role="tabpanel">
                                <Table
                                    data={unconfirmedInstitutions}
                                    columns={unconfirmedInstitutionsColumns}
                                    showCount={true}
                                />
                            </MDBTabPane>

                            <MDBTabPane tabId="Ringgold mapping" role="tabpanel">
                                <Table
                                    data={noRinggoldInstitutions}
                                    columns={noRinggoldInstitutionsColumns}
                                    showCount={true}
                                />
                            </MDBTabPane>
                        </>
                    }
                />
            </div>

            <ArticleDetailsModal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                articleDetails={articleDetails}
                tabItems={userSettings.details}
                updateArticle={updateArticle}
            />
        </div>
    );
};


export default Enrichment;